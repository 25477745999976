.footer{
  width:100%;
  height: 340px;
  display:flex;
  justify-content:center;
  background-color:#ca1226;
  font-family:OpenSans-SemiBold;
  padding-bottom:22px;
  margin-left: 0%;
  
  &__feedback{
    height: 316px;
    background-color: #ececec;
    width: 100%;
    padding-top: 60px;
    margin-top: 60px;
    margin-left: 0%;
  
    &__title{
      text-align:center;
      font-size:40px;

      &.min{
        color: #6f6f6f;
        font-size: 18px;
      }
    }

    &__form{
      display:flex;
      flex-wrap:nowrap;
      justify-content:center;
      align-items:center;
      margin-top:4vw;
      width: 90%;
    }

    &__input{
      background-color: transparent;
      font-family: OpenSans-Regular;
      width: 460px;
      font-size: 24px;
      -webkit-appearance: none;
      height: 44px;
      outline: none;
      border: none;
      border-bottom: 1px solid #6f6f6f;
    }

    &__submit{
      cursor: pointer;
      font-family: OpenSans-Bold;
      font-size: 18px;
      line-height: 1;
      border: none;
      height: 50px;
      border-radius: 25px;
      background-color: #ca1226;
      padding: 10px 40px 12px;
      color: #fff;
      margin-left: 30px;
      transition: color .3s,background-color .3s;
      
      &:hover{
        background-color: rgba(202,18,38,.75);
      }
    }
  }
  
  &__bigtext{
    text-align: right; 
  }

  &__number{
    font-size:1.5vw!important;
    padding-left: 28px;
    text-align: right;
    height: 22px !important;
  
    &:nth-of-type(3n){
      margin-bottom:0.6vw;
    }
    // &:last-of-type{
    //   margin-bottom:0.6vw;
    // }
    &.lasty {
      margin-bottom:0.7vw;
    }
  }

  &__indent{
    margin-top:2.2vw;
  }

  &__indent-icons{
    margin-top:2vw;
  }

  &__indent-min{
    margin-top:1.2vw;
  }

  &__column{
    height:200px;
    margin-top:64px;
    margin-right: 120px;
    color:#fafafa;

    li{
      height: 25px;
      font-size:0.9rem;
      
      a{
        text-decoration:none;
        color:#fafafa;
        font-size: 1rem;

        &:hover{
          color: #fafafa !important;
          opacity: 0.6;
        }

        i{
          font-size: 26px;
          margin-right: 15px;
        }
      }
    }

    &:last-of-type{
      margin-right:0;
    }
  }

  .logo{
    width:24px;
    margin-right:20px;
  }
}


.fp-tableCell{
  .footer, .footer__feedback{
    width: 90%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 1024px) {
  .section{
    .footer, .footer__feedback{
      margin-left: 0 !important;
      width: 100vw !important;
    }
  }
  .footer{
    width: 100vw;
    margin-left: 0;
    height: auto;
    
    
    &__feedback{
      width: 100vw;
      margin-left: 0;
      height: auto;
      padding-bottom: 60px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    
      &__title{
        font-size:25px;
        text-align: left;
        width: 80%;
  
        &.min{
          
        }
      }
  
      &__form{
        form{
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          width: 90%
        }
      }
  
      &__input{
        width: 90%;
        font-size: 4.1vw;
        margin-bottom: 20px;
        background-color: #fff;
        border: 1px solid #cecece;
        border-radius: 5px;
        padding-top: 0;

        &::placeholder{
          padding-top: 0;
          color: #cecece;
          padding-left: 1vw;
        }
      }
  
      &__submit{
        margin: 0;
        width: 90%;
        border-radius: 5px;
        height: 44px;
        font-size: 17px;
      }
    }
    
    &__bigtext{
      
    }
  
    &__number{
      padding-left: 0;

      a{
        font-size:1.5rem !important;
        text-align: left;
      }
      
    
      &:nth-of-type(3n){
        
      }
    }
  
    &__indent{
      
    }
  
    &__indent-icons{
      
    }
  
    &__indent-min{
      
    }
  
    &__column{
      margin-right: 0;
      width: 70%;
  
      li{
        
        
        a{
          font-size: 1.2rem;
  
          i{
            
          }
        }
      }
  
      &:last-of-type{
        
      }
    }
  
    .logo{
     
    }
  }
  
  
  .footerslide{
    .footer{
      
  
      &__feedback{
        
      }
    }
  }
}